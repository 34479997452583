import React from 'react'

import CampaignFeatured from '../../../react4xp/components/CampaignFeatured'

function CampaignSectionPart({ title, text, image }) {
    return (
        <CampaignFeatured
            image={image}
            title={title}
            content={text}
        />
    )
}

export default (props) => <CampaignSectionPart {...props} />
